import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import FileIcon from "../components/abstractComponents/fileIcon";
import { useState, useEffect, useContext } from "react";
import ChatBubble from "@mui/icons-material/ChatBubble";
import TextField from "@mui/material/TextField";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MuiButton from "@mui/material/Button";
import Reply from "@mui/icons-material/Reply";
import {
  Button,
  Form,
  Modal,
  Spinner,
  Row,
  Col,
  Table,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { useEditActionMutation } from "../backend/hooks/action/mutationEditAction";
import Error from "./abstractComponents/error";
import { useCreationalActionsLazyQuery } from "../backend/hooks/contextMenu/queryGetCreationalActions";
import { useGetActionQuery } from "../backend/hooks/action/queryGetAction";
import { getPreviewURL } from "../helpers/urlHelper";
import ReactQuill from "react-quill";
import {
  formatStringToDateTime,
  replaceLast,
  quillModules,
  formats,
  MAX_COMMENT_SIZE,
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType,
  ActionType,
  ActionTypeType,
  ActionSourceType,
  ActionSource,
  shortenString,
  removeTags,
  getKeyByValue
} from "../helpers/stringHelper";
import Autocomplete from "@mui/material/Autocomplete";
import DOMPurify from "dompurify";
import { useCreateCommentMutation } from "../backend/hooks/pmxActivity/mutationCreateComment";
import { PmxActivityDetailsContext } from "../contexts/pmxActivityDetailsProvider";
import {
  Comment,
  Action,
  ActionHistory,
  ActionFileType
} from "../backend/types";
import { Link } from "react-router-dom";
import UserManualLink from "./abstractComponents/usermanual";
import HistoryVersionList from "./history/historyVersionList";

// Component used as a popup to edit an action.  qc_badge class is used for the button popup so that we know where the click came from
export default function EditAction(props: {
  openDirectly?: boolean | null;
  onClose?: Function | null;
  versionId?: String | null;
  actionId?: Number | null;
  refetch?: Function | null;
}) {
  const {
    isActivityLoading,
    errorActivity,
    activityId,
    activityData,
    refetchActivityDetails
  } = useContext(PmxActivityDetailsContext);

  const openDirectly = props.openDirectly === null ? false : props.openDirectly;
  const [currentAction, setCurrentAction] = useState<Action | null>(null);
  const [newDescription, setNewDescription] = useState<string>("");
  const [showPopup, setShowPopup] = useState(openDirectly);

  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus>(QualityCheckStatus.NOT_STARTED);
  const [isNonEditableAction, setIsNonEditableAction] =
    useState<boolean>(false);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >();
  const [newCommentText, setNewCommentText] = useState("");
  const [isUserReferenceVisible, setIsUserReferenceVisible] = useState(false);
  const [repliedToComment, setRepliedToComment] = useState<Comment | null>(
    null
  );
  const handleAutocompleteChange = (user: string | null | undefined) => {
    if (user) {
      const userSelected = activityData.allAccessUser.find((userLoop: any) =>
        user.endsWith(" - " + userLoop!.email)
      );
      setNewCommentText((prevComment) => {
        return replaceLast(
          "@",
          `<a href="mailto:${userSelected?.email}">${userSelected?.name}</a> `,
          prevComment
        );
      });
    }

    setIsUserReferenceVisible(false);
    document.getElementById("comment_editor_bottom")?.focus();
  };

  const handleCommentWriting = (commentText: string) => {
    if (commentText.includes("<p>@</p>") || commentText.includes(" @</p>")) {
      setIsUserReferenceVisible(true);
    } else if (isUserReferenceVisible) {
      setIsUserReferenceVisible(false);
    }

    if (newCommentText.length < MAX_COMMENT_SIZE) {
      setNewCommentText(commentText);
    } else {
      setNewCommentText(commentText.slice(0, MAX_COMMENT_SIZE));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isUserReferenceVisible) {
        document.getElementById("autocomplete-user-label")?.focus();
      }
    }, 100); //allow time to render
  }, [isUserReferenceVisible]);

  const {
    editActionMutation,
    isLoadingEditCommentMutation,
    errorEditActionMutation
  } = useEditActionMutation();

  const {
    getCreationalActions,
    creationalActions,
    isLoadingCreationalActions,
    errorCreationalActions,
    refetchCreationalActions
  } = useCreationalActionsLazyQuery();

  const { getAction, action, isActionLoading, errorAction, refetchAction } =
    useGetActionQuery();

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      setShowPopup(false);
    }
  };

  const handleSave = () => {
    editActionMutation({
      variables: {
        actionId: currentAction?.id,
        description: newDescription,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus)
      }
    }).then(() => {
      if (activityId) {
        refetchActivityDetails();
      }
      getAction({
        variables: {
          actionId: currentAction?.id
        }
      });
      if (props.refetch) {
        props.refetch();
      }
    });
  };

  const checkIfActionIsEditable = (currentAction: Action) => {
    if (
      [ActionType.DOWNLOAD, ActionType.ARCHIVE, ActionType.UNCOMPRESS].includes(
        ActionType[currentAction.actionType]
      )
    ) {
      setIsNonEditableAction(true);
      return false;
    } else {
      setIsNonEditableAction(false);
      return false;
    }
  };

  useEffect(() => {
    if (showPopup) {
      if (props.versionId) {
        getCreationalActions({
          variables: {
            versionIds: [props.versionId].flat(),
            activityId: activityId
          }
        });
      } else {
        getAction({
          variables: {
            actionId: props.actionId
          }
        });
      }
    }
  }, [showPopup]);

  useEffect(() => {
    if (creationalActions && creationalActions.getCreationalActions.actions) {
      const currentLoopingAction =
        creationalActions.getCreationalActions.actions[0];
      setCurrentAction(currentLoopingAction);
      setQualityCheckStatus(
        QualityCheckStatus[
          currentLoopingAction.actionQualityCheckStatus as QualityCheckStatusType
        ]
      );
      setNewDescription(currentLoopingAction.description!);
      checkIfActionIsEditable(creationalActions.getCreationalActions.actions);
    }
  }, [creationalActions]);

  useEffect(() => {
    if (action && action.getAction) {
      setCurrentAction(action.getAction);
      setQualityCheckStatus(
        QualityCheckStatus[
          action.getAction.actionQualityCheckStatus as QualityCheckStatusType
        ]
      );
      setNewDescription(action.getAction.description!);
      checkIfActionIsEditable(action.getAction);
    }
  }, [action]);

  const {
    addNewComment,
    isLoadingCreateCommentMutation,
    errorCreateCommentMutation
  } = useCreateCommentMutation();

  function saveNewComment() {
    addNewComment({
      variables: {
        text: newCommentText,
        conversationId: Number(selectedConversationId),
        repliedToCommentId: repliedToComment
          ? Number(repliedToComment?.id)
          : null,
        referencedFiles: [],
        referencedActions: [currentAction?.id]
      }
    }).then(() => {
      setSelectedConversationId(null);
      setNewCommentText("");
      setRepliedToComment(null);
      refetchActivityDetails();
    });
  }

  return (
    <>
      {/* context menu does not need to show edit/preview icon */}
      {!props.openDirectly && (
        <GridActionsCellItem
          icon={
            isNonEditableAction ? (
              <PreviewIcon className="edit_action_button" />
            ) : (
              <EditIcon className="edit_action_button" />
            )
          }
          label="Edit Action"
          onClick={() => setShowPopup(true)}
          showInMenu={false}
        />
      )}
      <Modal
        show={showPopup}
        onHide={handleClose}
        dialogClassName="modal-75w"
        aria-labelledby="custom-modal-styling-title"
        backdropClassName="popup_edit_action_modal"
        className="popup_edit_action_modal qc_badge"
      >
        {isLoadingCreationalActions ? (
          <>
            <br />
            <div className="center_div">
              <Spinner animation="border" className="spinner_color" />
              <p>Loading...</p>
            </div>
            <br />
          </>
        ) : (
          <>
            <Modal.Header closeButton className="qc_badge">
              <Modal.Title id="custom-modal-styling-title" className="qc_badge">
                {isNonEditableAction
                  ? `Details of Action Number ${currentAction?.id}`
                  : `Edit Action Number ${currentAction?.id}`}
              </Modal.Title>
              <UserManualLink
                url="/usermanual/creatingaction/"
                testId="um-creating-action"
                className="align-self-start ms-1"
              />
            </Modal.Header>
            <Modal.Body className="qc_badge">
              <h4>Details</h4>
              <Table
                className="action_details"
                striped
                bordered
                hover
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>
                      {ActionType[currentAction?.actionType as ActionTypeType]}
                    </td>
                  </tr>
                  <tr>
                    <td>Executed At</td>
                    <td>{currentAction?.startDatetime}</td>
                  </tr>
                  <tr>
                    <td>User</td>
                    <td>{currentAction?.user?.email}</td>
                  </tr>
                  <tr>
                    <td>External Folder</td>
                    <td>{currentAction?.sourceFolder}</td>
                  </tr>
                  <tr>
                    <td>External Files</td>
                    <td>
                      {currentAction?.sourceFiles
                        ? currentAction.sourceFiles.map((sourceFile, index) => (
                            <div
                              className="no_line_hight"
                              key={"ext-" + index + currentAction.id}
                            >
                              {sourceFile}
                            </div>
                          ))
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Environment</td>
                    <td>
                      {
                        ActionSource[
                          currentAction?.actionSource as ActionSourceType
                        ]
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{currentAction?.actionStatus}</td>
                  </tr>
                  <tr>
                    <td>Status Description</td>
                    <td>{currentAction?.actionStatusDescription}</td>
                  </tr>
                  <tr>
                    <td>Additional Details</td>
                    <td>{currentAction?.additionalDetails}</td>
                  </tr>
                  <tr>
                    <td>Files</td>
                    <td>
                      <div
                        key={currentAction?.id}
                        className="action_list_element"
                      >
                        {currentAction &&
                          currentAction?.actionFiles &&
                          currentAction.actionFiles.map(
                            (af: any, index: any) => {
                              if (af?.file) {
                                return (
                                  <div
                                    className="no_line_hight"
                                    key={index + currentAction.id}
                                  >
                                    <FileIcon
                                      fileName={af?.file.name}
                                      isValid={af?.file.isValid}
                                      fontSize={"small"}
                                    />{" "}
                                    <b>
                                      <a
                                        href={getPreviewURL(
                                          activityId
                                            ? (activityId?.toString() as string)
                                            : (currentAction?.pmxActivity
                                                ?.id as string),
                                          af?.file?.versionId!,
                                          af?.file?.name!,
                                          af?.file?.repo?.id!,
                                          true
                                        )}
                                      >
                                        <span className="repo_name_link">
                                          {af?.file?.repo?.name}/
                                        </span>
                                        {af?.file.name} - ({af?.file?.revision}){" "}
                                      </a>
                                    </b>{" "}
                                    -{" "}
                                    {getKeyByValue(
                                      ActionFileType,
                                      af?.actionFileType
                                    )}
                                  </div>
                                );
                              } else {
                                return "";
                              }
                            }
                          )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Job</td>
                    <td>
                      {currentAction?.jobId && (
                        <Link
                          to={`/activity/${currentAction?.pmxActivity?.id}/execution/${currentAction?.jobId}`}
                        >
                          {currentAction?.jobId}
                        </Link>
                      )}
                    </td>
                  </tr>
                  {activityData?.conversations?.length > 0 && (
                    <>
                      {activityData.conversations.map((conversation: any) =>
                        conversation.comments
                          .filter(
                            (comment: any) =>
                              comment.referencedActions &&
                              comment.referencedActions
                                .map((action: Action) => action.id)
                                .includes(currentAction?.id)
                          )
                          .map((comment: any) => (
                            <tr key={comment?.id}>
                              <td>
                                Referenced Comment #{comment?.id}
                                <br />
                                Conversation: {conversation.name}
                              </td>
                              <td className="comment_edit_action_holder">
                                {comment?.replyComment && (
                                  <blockquote className="blockquote">
                                    Replied to: &quot;
                                    {shortenString(
                                      removeTags(comment?.replyComment.text)
                                    )}
                                    &quot;
                                  </blockquote>
                                )}
                                <div
                                  className="comment_conversation_inner clear_float"
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      String(comment?.text)
                                    )
                                  }}
                                ></div>
                                <div className="comment_conversation_actions">
                                  <span className="comment_user_details">
                                    {comment?.createdBy} -{" "}
                                    {formatStringToDateTime(comment?.created)}
                                  </span>
                                  <MuiButton
                                    variant="text"
                                    onClick={() => {
                                      setRepliedToComment(comment);
                                      setSelectedConversationId(
                                        conversation.id
                                      );
                                    }}
                                    className="small_mui_button float-end reply_to_comment_button"
                                    size="small"
                                  >
                                    <Reply />
                                  </MuiButton>
                                </div>
                              </td>
                            </tr>
                          ))
                      )}
                      <tr>
                        <td>Write Comment</td>
                        <td>
                          <DropdownButton
                            title={
                              <>
                                <ChatBubble /> Write Comment in Conversation
                              </>
                            }
                            id="view_conversation_button"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {activityData?.conversations.map((conv: any) => (
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedConversationId(conv.id);
                                }}
                                key={conv.id}
                              >
                                {conv.name}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </td>
                      </tr>
                    </>
                  )}
                  {selectedConversationId && (
                    <>
                      <tr>
                        <td>Comment</td>
                        <td>
                          <div>
                            <ReceiptIcon /> Action ID {currentAction?.id}
                            <br />
                          </div>
                          {repliedToComment && (
                            <Row>
                              <Col>
                                <MuiButton className="comment_edit_action_holder comment_reply_link full_100_width">
                                  <blockquote className="blockquote">
                                    Reply: &quot;
                                    {shortenString(
                                      removeTags(repliedToComment?.text)
                                    )}
                                    &quot;
                                    <MuiButton
                                      variant="text"
                                      onClick={() => setRepliedToComment(null)}
                                      id="delete_replied_to_comment"
                                      className="float-end small_mui_button"
                                      size="small"
                                    >
                                      X
                                    </MuiButton>
                                  </blockquote>
                                </MuiButton>
                              </Col>
                            </Row>
                          )}
                          <div data-text-editor="name">
                            <ReactQuill
                              className="comment_editor"
                              modules={quillModules}
                              formats={formats}
                              value={newCommentText}
                              onChange={handleCommentWriting}
                              placeholder="Enter your comment"
                              id="comment_editor_bottom"
                              bounds={`[data-text-editor="name"]`}
                            />
                            {isUserReferenceVisible && (
                              <Autocomplete
                                id="autocomplete-user"
                                disablePortal
                                onChange={(event, value) =>
                                  handleAutocompleteChange(String(value))
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                options={activityData?.allAccessUser.map(
                                  (user: any) =>
                                    `${user!.name} - ${user!.email}`
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select a user"
                                    variant="standard"
                                    id="autocomplete-user-label"
                                  />
                                )}
                              />
                            )}
                            <div
                              className={
                                newCommentText.length >= MAX_COMMENT_SIZE
                                  ? "comment_user_details red_form"
                                  : "comment_user_details"
                              }
                            >
                              {newCommentText.length}/{MAX_COMMENT_SIZE}{" "}
                              characters
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <Button
                            variant="secondary"
                            onClick={() => {
                              setSelectedConversationId(null);
                            }}
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            id="save_comment_button"
                            variant="primary"
                            disabled={
                              !newCommentText ||
                              newCommentText === "<p><br></p>"
                            } //default editor setting
                            onClick={saveNewComment}
                          >
                            Save Comment
                          </Button>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <br />
              {(isLoadingEditCommentMutation ||
                isActionLoading ||
                isActivityLoading ||
                isLoadingCreateCommentMutation) && (
                <div className="center_div qc_badge">
                  <br />
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              )}
              <br />
              <br />
              <h4>
                {" "}
                {isNonEditableAction
                  ? `Action Properties`
                  : `Editable Properties`}
              </h4>
              <Row className="qc_badge">
                <Col xs={6}>
                  <Form.Label>Description</Form.Label>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Description"
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    disabled={
                      isLoadingEditCommentMutation ||
                      isNonEditableAction ||
                      (activityId &&
                        Number(currentAction?.pmxActivity?.id) !==
                          Number(activityId))
                    }
                  />
                </Col>
              </Row>
              <br />
              <Row className="qc_badge">
                <Col xs={6}>
                  <Form.Label>Quality Controlled Status</Form.Label>
                  <UserManualLink
                    url="/usermanual/qualitycontrol/"
                    testId="um-qc"
                    className="align-self-start ms-1"
                  />
                </Col>
                <Col xs={6}>
                  <Form.Select
                    disabled={
                      isLoadingEditCommentMutation ||
                      isNonEditableAction ||
                      ActionType[
                        currentAction?.actionType as ActionTypeType
                      ] === ActionType.COPY ||
                      ActionType[
                        currentAction?.actionType as ActionTypeType
                      ] === ActionType.HIDE ||
                      ActionType[
                        currentAction?.actionType as ActionTypeType
                      ] === ActionType.MOVE ||
                      ActionType[
                        currentAction?.actionType as ActionTypeType
                      ] === ActionType.RENAME ||
                      ActionType[
                        currentAction?.actionType as ActionTypeType
                      ] === ActionType.UNCOMPRESS ||
                      ActionType[
                        currentAction?.actionType as ActionTypeType
                      ] === ActionType.RESTORE ||
                      (activityId &&
                        Number(currentAction?.pmxActivity?.id) !==
                          Number(activityId))
                    }
                    value={String(
                      getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                    )}
                    onChange={changeQualityCheckedStatus}
                    key="edit_action_quality_controlled"
                    id="edit_action_quality_controlled"
                  >
                    <option key={null}>None</option>
                    {Object.entries(QualityCheckStatus).map((element) => (
                      <option key={element[0]} value={element[0]}>
                        {element[1]}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <br />
              <Row className="qc_badge justify-content-end">
                <Col xs={6} className="d-flex flex-row-reverse">
                  <Button
                    className="mx-2"
                    variant="primary"
                    data-testid="saveBtn"
                    onClick={handleSave}
                    disabled={
                      isLoadingEditCommentMutation ||
                      isNonEditableAction ||
                      (activityId &&
                        Number(currentAction?.pmxActivity?.id) !==
                          Number(activityId))
                    }
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
              <br />
              {currentAction?.actionHistory &&
                currentAction?.actionHistory?.length > 0 && (
                  <>
                    <h4>Action Versions</h4>
                    <HistoryVersionList
                      isNonEditableAction={isNonEditableAction}
                      actionHistory={
                        currentAction.actionHistory as ActionHistory[]
                      }
                      refetchAction={
                        props.versionId
                          ? refetchCreationalActions
                          : refetchAction
                      }
                      isActionLoading={isActionLoading}
                      actionId={currentAction.id}
                    />
                  </>
                )}
            </Modal.Body>
            <Modal.Footer className="qc_badge">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
            {(errorEditActionMutation ||
              errorAction ||
              errorCreationalActions ||
              errorCreateCommentMutation ||
              errorActivity) && (
              <Error
                error={
                  errorEditActionMutation ||
                  errorCreationalActions ||
                  errorAction ||
                  errorCreateCommentMutation ||
                  errorActivity
                }
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
}
