import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useHideObjectsMutation } from "../backend/hooks/contextMenu/fileOperations/mutationHideObjects";
import { useUpdateActionStatusMutation } from "../backend/hooks/action/mutationUpdateActionStatus";
import { useDownloadLinksQuery } from "../backend/hooks/importExportMenu/queryGetDownloadLinks";
import {
  ActionStatus,
  GetRepoObjectQueryData,
  Repo,
  RepoObject
} from "../backend/types";

// Hook used for upload and download of files
export function useTableViewHook(
  currentSelectedPath: string,
  activityId: number,
  refetchRepoObjects: Function,
  tableMenuWasOpenOnPath: string,
  tree: any[],
  createFolderMutation: Function,
  setTableMenuWasOpenOnPath: Function,
  setIsActionOfFilesVisible: Function,
  setIsTracebilityReportVisible: Function,
  setIsEditActionVisible: Function,
  currentFileList: GetRepoObjectQueryData,
  repo: Repo,
  repoObjects: any,
  getRepoObjects: Function,
  refetchRepoObjectsForPath: Function
) {
  const [anchorElFolder, setAnchorElFolder] = useState<null | HTMLElement>(
    null
  );
  const [isDownloadExternalLinkVisible, setIsDownloadExternalLinkVisible] =
    useState(false);
  const [hideComment, setHideComment] = useState("");
  const [anchorElFile, setAnchorElFile] = useState<null | HTMLElement>(null);
  const [isHideModalOpen, setIsHideModalOpen] = useState(false);
  const [isCompressModalOpen, setIsCompressModalOpen] = useState(false);
  const [isUncompressModalOpen, setIsUncompressModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isCopyMoveModalOpen, setIsCopyMoveModalOpen] = useState(false);

  const [folderName, setFolderName] = useState("");
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [currentSubArray, setCurrentSubArray] = useState<any[]>([]);

  const [currentCommentsForSelectedFile, setCurrentCommentsForSelectedFile] =
    useState<null | any[]>([]);
  const [isFileContextMenuOpen, setIsFileContextMenuOpen] = useState(false);
  const [copyOrMoveAction, setCopyOrMoveAction] = useState("'");

  const { updateActionStatus } = useUpdateActionStatusMutation();

  const {
    getDownloadLinkForFiles,
    errorDownload: error,
    downloadUrls
  } = useDownloadLinksQuery();

  useEffect(() => {
    if (typeof downloadUrls !== "undefined") {
      if (downloadUrls.getDownloadLinks.actionStatus === ActionStatus.Pending) {
        try {
          downloadUrls?.getDownloadLinks?.presignedUrls.forEach((url: any) => {
            window.open(url.url);
          });
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Success
            }
          });
        } catch (err: any) {
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Error,
              actionStatusDescription: err.toString()
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadUrls]);

  const handleSelectMultipleRows = (e: any) => {
    !isCopyMoveModalOpen && setSelectedRows(e);
  };

  const downloadMultipleFiles = () => {
    getDownloadLinkForFiles({
      variables: {
        absolutePaths:
          selectedRows.length > 0 ? selectedRows : [tableMenuWasOpenOnPath],
        activityId,
        versionIds: [],
        repoId: currentFileList.repo.id
      }
    });
    handleCloseFileMenu();
  };

  const createFolder = () => {
    createFolderMutation(folderName);
    setTimeout(() => {
      setFolderName("");
      refetchRepoObjectsForPath();
    }, 3000);
  };

  const closeHideModal = () => {
    setIsHideModalOpen(false);
    setHideComment("");
  };

  const handleClickFolder = (
    event: React.MouseEvent<HTMLButtonElement>,
    absolutePath: string
  ) => {
    if (!selectedRows.includes(absolutePath)) {
      setSelectedRows([]);
    }
    setAnchorElFolder(event.currentTarget);
    setTableMenuWasOpenOnPath(absolutePath);
  };

  const handleCloseFolder = () => {
    setAnchorElFolder(null);
  };

  const handleCloseCommentMenu = () => {
    setCurrentCommentsForSelectedFile([]);
    handleCloseFileMenu();
  };

  const handleCloseFileMenu = () => {
    setAnchorElFile(null);
    setIsFileContextMenuOpen(false);
    setTableMenuWasOpenOnPath("");
  };

  const handleOpenProperties = () => {
    setIsActionOfFilesVisible(true);
    setIsFileContextMenuOpen(false);
    setAnchorElFile(null);
  };

  const handleOpenEditAction = () => {
    setIsEditActionVisible(true);
    setIsFileContextMenuOpen(false);
    setAnchorElFile(null);
  };

  const handleOpenTracebilityReport = () => {
    setIsTracebilityReportVisible(true);
    setIsFileContextMenuOpen(false);
    setAnchorElFile(null);
  };

  const handleOpenExternalDownloadLink = () => {
    setIsDownloadExternalLinkVisible(true);
    setIsFileContextMenuOpen(false);
    setAnchorElFile(null);
  };

  const handleClickFile = (
    event: React.MouseEvent<HTMLButtonElement>,
    absolutePath: string
  ) => {
    if (!selectedRows.includes(absolutePath)) {
      setSelectedRows([]);
    }
    setAnchorElFile(event.currentTarget);
    setIsFileContextMenuOpen(true);
    setTableMenuWasOpenOnPath(absolutePath);
  };

  const handleClickComment = (
    event: React.MouseEvent<HTMLButtonElement>,
    comments: any[]
  ) => {
    setAnchorElFile(event.currentTarget);
    setCurrentCommentsForSelectedFile(comments);
  };

  useEffect(() => {
    if (currentSelectedPath && repo && Number(repo.id) !== 0) {
      let nameSplit = currentSelectedPath.split("/");
      let subArray = tree ? tree : [];

      nameSplit.forEach((name: string) => {
        if (name) {
          subArray = subArray
            ? subArray.find((element: any) => element.name === name)?.content
            : null;
        }
      });

      setCurrentSubArray(subArray);
      getRepoObjects();
    }
  }, [currentSelectedPath, repo.id]);

  useEffect(() => {
    if (repoObjects && repoObjects.length > 0) {
      const finalArray: RepoObject[] = [];

      repoObjects?.forEach((object: RepoObject) => {
        finalArray.push({
          ...object,
          absolutePath: object.name,
          name: object.name.replace(currentSelectedPath, "").replace("/", "")
        });
      });

      if (
        finalArray &&
        currentSubArray &&
        finalArray.length !== currentSubArray.length
      ) {
        refetchRepoObjects();
      }

      setCurrentSubArray(finalArray);
    }
  }, [repoObjects]);

  const { hideObjectsMutation, errorMutationHide, isLoadingMutationHide } =
    useHideObjectsMutation();

  const openHideModal = () => {
    setIsFileContextMenuOpen(false);
    setIsHideModalOpen(true);
    setAnchorElFile(null);
    setAnchorElFolder(null);
  };

  const openCompressModal = () => {
    setIsFileContextMenuOpen(false);
    setIsCompressModalOpen(true);
    setAnchorElFile(null);
    setAnchorElFolder(null);
  };

  const openUncompressModal = () => {
    setIsFileContextMenuOpen(false);
    setIsUncompressModalOpen(true);
    setAnchorElFile(null);
    setAnchorElFolder(null);
  };

  const openRenameModal = () => {
    setIsFileContextMenuOpen(false);
    setIsRenameModalOpen(true);
    setAnchorElFile(null);
    setAnchorElFolder(null);
  };

  const openCopyMoveModal = (typeOfAction: string) => {
    setIsFileContextMenuOpen(false);
    setIsCopyMoveModalOpen(true);
    setCopyOrMoveAction(typeOfAction);
    setAnchorElFile(null);
    setAnchorElFolder(null);
  };

  function hideObjects() {
    setIsFileContextMenuOpen(false);
    hideObjectsMutation({
      variables: {
        absolutePaths:
          selectedRows.length > 0 ? selectedRows : [tableMenuWasOpenOnPath],
        activityId,
        comment: hideComment
      }
    }).then(() => {
      setTimeout(() => {
        refetchRepoObjects();
        refetchRepoObjectsForPath();
        closeHideModal();
      }, 1000);
    });
  }

  const onCloseExternalDownloadModal = () => {
    setIsDownloadExternalLinkVisible(false);
    setTableMenuWasOpenOnPath("");
  };

  const getRowId = (row: any) => row.absolutePath + row.versionId;

  return {
    getDownloadLinkForFiles,
    error: error,
    downloadUrls,
    currentSubArray,
    selectedRows,
    downloadMultipleFiles,
    handleSelectMultipleRows,
    anchorElFolder,
    handleCloseFolder,
    anchorElFile,
    currentCommentsForSelectedFile,
    handleCloseCommentMenu,
    isFileContextMenuOpen,
    handleCloseFileMenu,
    handleOpenProperties,
    handleOpenTracebilityReport,
    folderName,
    setFolderName,
    createFolder,
    setCurrentCommentsForSelectedFile,
    getRowId,
    hideObjects,
    isHideModalOpen,
    setIsHideModalOpen,
    closeHideModal,
    hideComment,
    setHideComment,
    openHideModal,
    isRenameModalOpen,
    setIsRenameModalOpen,
    openRenameModal,
    handleClickFile,
    handleClickComment,
    handleClickFolder,
    handleOpenEditAction,
    setIsCopyMoveModalOpen,
    isCopyMoveModalOpen,
    openCopyMoveModal,
    copyOrMoveAction,
    handleOpenExternalDownloadLink,
    isDownloadExternalLinkVisible,
    onCloseExternalDownloadModal,
    setIsDownloadExternalLinkVisible,
    isCompressModalOpen,
    setIsCompressModalOpen,
    isUncompressModalOpen,
    setIsUncompressModalOpen,
    openUncompressModal,
    openCompressModal,
    setSelectedRows,
    errorMutationHide,
    isLoadingMutationHide
  };
}
