import { Card, CardContent, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Job } from "../../backend/executionTypes";
import { Link, useParams } from "react-router-dom";
import EditAction from "../editAction";
import { ActionType, QualityCheckStatus } from "../../helpers/stringHelper";
import { ActionFile, ActionFileType, RepoObject } from "../../backend/types";
import { getPreviewURL } from "../../helpers/urlHelper";
import { useGetActionQuery } from "../../backend/hooks/action/queryGetAction";
import { Col, Row, Spinner } from "react-bootstrap";
import Error from "../abstractComponents/error";

interface ExecutionActionDetailsProps {
  job: Job;
}
export function ExecutionActionDetails(props: ExecutionActionDetailsProps) {
  const { job } = props;
  const { activityId } = useParams<{ activityId: string }>();
  const [outputFiles, setOutputFiles] = useState<
    [ActionFile] | null | undefined
  >(null);
  const [inputFiles, setInputFiles] = useState<[ActionFile] | null | undefined>(
    null
  );
  const [scriptFile, setScriptFile] = useState<RepoObject | null | undefined>(
    null
  );
  const [isMoreFilesLinked, setIsMoreFilesLinked] = useState<
    boolean | null | undefined
  >(null);
  const { getAction, action, isActionLoading, errorAction } =
    useGetActionQuery();

  useEffect(() => {
    if (job?.actionId) {
      getAction({
        variables: {
          actionId: job.actionId
        }
      }).then(({ data }) => {
        const actionFiles = data.getAction.actionFiles as [ActionFile];
        const outputs = actionFiles.filter(
          (file) => file.actionFileType === ActionFileType.Output
        );
        const inputs = actionFiles.filter(
          (file) => file.actionFileType === ActionFileType.Input
        );
        const script = actionFiles.find(
          (file) => file.actionFileType === ActionFileType.Script
        )?.file;
        const isMoreFilesLinked = data.getAction.hasAdditionalFilesLinked;

        setOutputFiles(outputs as [ActionFile]);
        setInputFiles(inputs as [ActionFile]);
        setScriptFile(script as RepoObject);
        setIsMoreFilesLinked(isMoreFilesLinked);
      });
    }
  }, [job]);

  const CreatePreviewLink = (props: {
    file: RepoObject | undefined;
    type: string;
  }) => {
    const { file, type } = props;
    const urlPreview = getPreviewURL(
      activityId.toString(),
      file?.versionId as String,
      file?.name as String,
      file?.repo?.id as String
    );

    return (
      <Link
        role="button"
        key={`${file?.repo?.name}/${file?.name}`}
        to={urlPreview}
      >
        <Chip
          className={`m-2 job-${type}`}
          key={`${file?.repo?.name}/${file?.name}`}
          label={`${file?.repo?.name}/${file?.name} (${file?.revision})`}
        />
      </Link>
    );
  };

  const getFullFilePath = (type: string) => {
    switch (type) {
      case "script":
        return (
          <CreatePreviewLink type={type} file={scriptFile as RepoObject} />
        );
      case "inputs":
        return (
          <>
            {inputFiles?.map((actionFile: ActionFile) => (
              <CreatePreviewLink
                type={type}
                key={`input_${actionFile?.file?.versionId}`}
                file={actionFile.file as RepoObject}
              ></CreatePreviewLink>
            ))}
            {isMoreFilesLinked && <div>And More Files...</div>}
          </>
        );
      case "outputs":
        return (
          <>
            {outputFiles?.map((actionFile: ActionFile) => (
              <CreatePreviewLink
                type={type}
                key={`output_${actionFile?.file?.versionId}`}
                file={actionFile.file as RepoObject}
              ></CreatePreviewLink>
            ))}
          </>
        );
      default:
    }
  };

  return (
    <>
      <div className="job-action-details mt-2">
        <Card>
          <CardContent className="exeuction-heading">
            <Typography variant="h5" component="div">
              Job Action
            </Typography>
          </CardContent>

          {isActionLoading ? (
            <div className="center_div mt-2">
              <Spinner animation="border" className="spinner_color" />
              <p>Loading...</p>
            </div>
          ) : (
            <CardContent>
              <Row className="mb-3">
                <Col>
                  <span className="execution-subtitle">Action ID</span>
                  <br />
                  {job?.actionId}
                  {action && (
                    <EditAction
                      action={action.getAction}
                      actionId={parseInt(job?.actionId)}
                    />
                  )}
                </Col>
                <Col>
                  <span className="execution-subtitle">Action Type</span>
                  <br />
                  {ActionType[job?.actionType]}{" "}
                </Col>
                <Col>
                  <span className="execution-subtitle">QC Status</span>
                  <br />
                  {job?.qualityCheckStatus &&
                    QualityCheckStatus[job?.qualityCheckStatus]}
                </Col>
              </Row>
              <Row className="mb-3">
                <div>
                  <span className="execution-subtitle">Script</span>
                  <br />
                  {action?.getAction &&
                    action?.getAction.actionFiles &&
                    getFullFilePath("script")}
                </div>
              </Row>
              <Row className="mb-3">
                <div>
                  <span className="execution-subtitle">Inputs</span>
                  <br />
                  {action?.getAction &&
                    action?.getAction.actionFiles &&
                    getFullFilePath("inputs")}
                </div>
              </Row>
              <Row className="mb-3">
                <div>
                  <span className="execution-subtitle">Output</span>
                  <br />
                  {action?.getAction &&
                    action?.getAction.actionFiles &&
                    getFullFilePath("outputs")}
                </div>
              </Row>
            </CardContent>
          )}
        </Card>
        {errorAction && <Error error={errorAction} />}
      </div>
    </>
  );
}
