import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { DataGridPro, GridRowModel } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState, useMemo } from "react";
import {
  Nav,
  NavDropdown,
  Col,
  Container,
  Row,
  Spinner,
  Navbar,
  Modal,
  Form,
  Button
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useGetHistoricRepoObjectsPathQuery } from "../../backend/hooks/history/queryGetHistoricRepoObjectsPath";
import { useGetHistoricRepoObjectsQuery } from "../../backend/hooks/history/queryGetHistoricRepoObjectsTreeView";
import {
  Action,
  GetRepoObjectQueryData,
  RepoObject
} from "../../backend/types";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { MenuContext, columnsRender } from "../../helpers/tableViewHelper";
import getTreeHierarchyFromRepoObjectList from "../../helpers/treeHelper";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { useAuth } from "../authContextProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { TreeViewListFilesInRepo } from "../pmxActivity/treeViewListFilesInRepo";
import RestoreIcon from "@mui/icons-material/Restore";
import { useRestoreFilePathMutation } from "../../backend/hooks/history/mutationRestoreFilePath";
import Success from "../abstractComponents/success";
import EditAction from "../editAction";
import { useGetLastActionQuery } from "../../backend/hooks/action/queryGetActions";
import { FilePreview } from "../pmxActivity/filePreview";
import { useHistoryVersionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetHistoryVersions";
import TracebilityReport from "../contextMenu/tracebilityReport";
import ActionsOfFiles from "../contextMenu/actionsOfFiles";
import CreateHistoryLabel from "../history/createHistoryLabel";
import { useGetActionQuery } from "../../backend/hooks/action/queryGetAction";
import { CreateInteractiveModal } from "../interactive/createInteractiveModal";
import { convertToIsoString } from "../../helpers/stringHelper";

export function HistoryView() {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const { getFileVersions } = useHistoryVersionsLazyQuery();
  const {
    getAction,
    action: actionPulled,
    isActionLoading: isActionLoadingPulled,
    errorAction: errorActionPulled
  } = useGetActionQuery();
  const query: any = useQuery();
  const historyDate = query.get("historicalDate");
  const queryParams = {
    historicDatetime: historyDate || undefined,
    actionId: query.get("actionId") || undefined
  };

  const { activityId, activityData, isActivityLoading } = useContext(
    PmxActivityDetailsContext
  );

  const { files, isLoading, error } = useGetHistoricRepoObjectsQuery(
    activityData,
    queryParams
  );

  const {
    getLastAction,
    action: lastAction,
    isActionLoading,
    errorAction
  } = useGetLastActionQuery();

  const [actionId, setActionId] = useState(query.get("actionId"));

  useEffect(() => {
    if (historyDate) {
      getLastAction({
        variables: {
          activityId: activityId,
          createdBefore: convertToIsoString(historyDate)
        }
      });
    } else {
      getAction({
        variables: {
          actionId: actionId
        }
      });
    }
  }, [historyDate]);

  useEffect(() => {
    if (lastAction) {
      setActionId(lastAction.id);
      setCurrentAction(lastAction);
    }
  }, [lastAction]);
  useEffect(() => {
    if (actionPulled?.getAction) {
      setCurrentAction(actionPulled?.getAction);
    }
  }, [actionPulled]);

  const {
    restoreFilePathMutation,
    isLoadingRestoreMutation,
    errorRestoreMutation,
    dataMutation
  } = useRestoreFilePathMutation();

  const [currentAction, setCurrentAction] = useState<Action | null>(null);
  const [treeHierarchy, setTreeHierarchy] = useState<any[]>([]);
  const [currentSubArray, setCurrentSubArray] = useState<any[]>([]);
  const [currentSelectedPath, setCurrentSelectedPath] = useState("/");
  const [currentSelectedVersionId, setCurrentSelectedVersionId] = useState("");
  const [currentSelectedRevision, setCurrentSelectedRevision] = useState("");
  const [currentSelectedRepoId, setCurrentSelectedRepoId] = useState<
    number | null
  >(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [comment, setComment] = useState("");
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false);
  const [isActionOfFilesVisible, setIsActionOfFilesVisible] = useState(false);
  const [isTracebilityReportVisible, setIsTracebilityReportVisible] =
    useState(false);
  const [openPropertiesModal, setOpenPropertiesModal] =
    useState<boolean>(false);

  const [currentFileList, setCurrentFileList] = useState<
    GetRepoObjectQueryData | { willRefreshRunAsync?: boolean }
  >({
    repoObjects: [],
    isReadOnlyRepository: true,
    repo: {
      id: "",
      name: "",
      isStandard: true
    },
    willRefreshRunAsync: false
  });

  const { filesPath, isLoadingPath, errorPath } =
    useGetHistoricRepoObjectsPathQuery(
      currentSelectedRepoId,
      queryParams,
      currentSelectedPath
    );

  useEffect(() => {
    if (activityData && activityData.mainRepository.id) {
      setCurrentSelectedRepoId(Number(activityData.mainRepository.id));
    }
  }, [activityData]);

  useEffect(() => {
    if (files) {
      let currentFiles;
      if (currentSelectedRepoId) {
        currentFiles = files.find(
          (data: GetRepoObjectQueryData) =>
            Number(data.repo.id) === Number(currentSelectedRepoId)
        );
      } else {
        currentFiles = files.find(
          (data: GetRepoObjectQueryData) => !data.isReadOnlyRepository
        );
        if (currentFiles) {
          setCurrentSelectedRepoId(Number(currentFiles.repo!.id!));
        }
      }

      if (currentFiles) {
        setCurrentFileList(currentFiles);
      }
    }
  }, [files, currentSelectedRepoId, currentSelectedPath]);

  useEffect(() => {
    if (treeHierarchy[0] && Number(treeHierarchy[0]?.repo.id) !== 0) {
      let nameSplit = currentSelectedPath.split("/");
      let subArray = treeHierarchy
        ? treeHierarchy.find(
            (tree: GetRepoObjectQueryData) =>
              Number(tree.repo.id) === Number(currentSelectedRepoId)
          )?.repoObjects
        : [];

      nameSplit.forEach((name: string) => {
        if (name) {
          subArray = subArray
            ? subArray.find((element: any) => element.name === name)?.content
            : null;
        }
      });
      setCurrentSubArray(subArray);
    }
  }, [currentSelectedPath, treeHierarchy]);

  const restoreSelectedPaths = () => {
    restoreFilePathMutation({
      variables: {
        paths: selectedRows,
        activityId: activityId,
        historicDatetime: files[0].historicDatetime,
        comment: comment
      }
    }).then(() => {
      setSelectedRows([]);
      setComment("");
    });
    setIsRestoreModalVisible(false);
  };

  useEffect(() => {
    const tree = getTreeHierarchyFromRepoObjectList(files);
    setTreeHierarchy(tree);
  }, [files]);

  const onNodeSelectFunction = (path: string, versionId: string) => {
    setCurrentSelectedPath(path);
    if (versionId) {
      setCurrentSelectedVersionId(versionId);
      getFileVersions({
        variables: {
          repoId: currentSelectedRepoId,
          versionId: versionId
        }
      }).then(({ data }) => {
        setCurrentSelectedRevision(
          data.getHistoryVersions.find(
            (version: any) => version.versionId === versionId
          ).revision
        );
      });
    }
  };

  const { decodedToken } = useAuth();

  return (
    <>
      <NavBarPmxActivity activeIndex={4} />

      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/history`}>History</Link>,
          <Typography>Historical View</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <ErrorBoundary>
          <Container fluid className="browse-pmx-activity-container">
            <h4>Historical View</h4>
            <Row>
              <Col xs="6">
                <div>
                  Viewing historical view for the action ID: <b>{actionId}</b>
                  {actionId && <EditAction actionId={actionId} />}
                </div>
                {currentAction && currentAction.historyLabel?.name && (
                  <div>
                    History label: <b>{currentAction.historyLabel?.name}</b>
                  </div>
                )}
              </Col>
              <Col xs="3">
                <CreateInteractiveModal
                  repos={files}
                  actionId={actionId}
                  tree={treeHierarchy}
                  disabeld={isLoading}
                />
              </Col>
              <Col xs="3">
                <Navbar
                  id="history_nav"
                  className="table_view_navbar"
                  collapseOnSelect
                  expand="lg"
                  bg="light"
                  variant="light"
                  style={{ float: "right" }}
                >
                  <Nav>
                    {actionId && (
                      <CreateHistoryLabel
                        activityId={activityId}
                        actionId={actionId}
                        onComplete={() => {
                          if (historyDate) {
                            getLastAction({
                              variables: {
                                pmxActivityId: activityId,
                                createdBefore: convertToIsoString(historyDate)
                              }
                            });
                          }
                          if (actionId) {
                            getAction({
                              variables: {
                                actionId: actionId
                              }
                            });
                          }
                        }}
                      />
                    )}

                    {(isActionLoading || isActionLoadingPulled) && (
                      <Spinner animation="border" className="spinner_color" />
                    )}
                    {selectedRows.length !== 0 &&
                      Number(currentSelectedRepoId) ===
                        Number(activityData?.mainRepository?.id) && (
                        <NavDropdown.Item
                          onClick={() => {
                            setIsRestoreModalVisible(true);
                          }}
                          id="restoreSelectedPaths"
                          className="nav-link nav_item_button"
                        >
                          <RestoreIcon className="table_view_svg" /> Restore
                        </NavDropdown.Item>
                      )}
                  </Nav>
                </Navbar>
              </Col>
            </Row>

            {isLoading || isActivityLoading || isLoadingRestoreMutation ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            ) : (
              <Row className="pmx-activity-row-container">
                <Col
                  xs="4"
                  className="d-block tree-view-column historical_tree_view"
                >
                  {treeHierarchy?.map((repoQuery: any) => (
                    <ErrorBoundary key={repoQuery.repo.id}>
                      <TreeViewListFilesInRepo
                        repo={repoQuery}
                        onNodeSelect={onNodeSelectFunction}
                        currentSelectedPath={currentSelectedPath}
                        currentSelectedRepoId={currentSelectedRepoId}
                        setCurrentSelectedRepoId={setCurrentSelectedRepoId}
                      />
                    </ErrorBoundary>
                  ))}
                </Col>
                <Col xs="8">
                  <div
                    className="table-view-list-container"
                    style={{ height: "90%" }}
                  >
                    {!currentSelectedPath?.endsWith("/") &&
                    currentSelectedPath !== "" ? (
                      <ErrorBoundary>
                        <FilePreview
                          absolutePath={currentSelectedPath}
                          versionId={currentSelectedVersionId}
                          revision={currentSelectedRevision}
                          setTableMenuWasOpenOnPath={() => {}}
                          setIsActionOfFilesVisible={setIsActionOfFilesVisible}
                          openPropertiesModal={openPropertiesModal}
                          setIsPreselectedRevision={() => {}}
                          setIsTracebilityReportVisible={
                            setIsTracebilityReportVisible
                          }
                          repoId={currentFileList!.repo!.id!}
                        />
                      </ErrorBoundary>
                    ) : (
                      <MenuContext.Provider
                        value={{
                          handleClickFolder: () => {},
                          handleClickFile: () => {},
                          handleClickComment: () => {},
                          onNodeSelect: onNodeSelectFunction
                        }}
                      >
                        <DataGridPro
                          disableVirtualization={
                            decodedToken["email"] === "test-user@bayer.com"
                          }
                          rows={
                            filesPath
                              ? (filesPath as readonly GridRowModel[])
                              : currentSubArray
                                ? (currentSubArray as readonly GridRowModel[])
                                : []
                          }
                          columns={columnsRender(() => {}, true, true, false)}
                          pageSize={50}
                          rowHeight={50}
                          disableSelectionOnClick
                          checkboxSelection
                          className="browser_repository_table"
                          selectionModel={selectedRows}
                          onSelectionModelChange={(e: any) =>
                            setSelectedRows(e)
                          }
                          getRowId={(row) => row.absolutePath}
                          classes={
                            currentFileList.isReadOnlyRepository
                              ? {
                                  virtualScroller: "opacity-60"
                                }
                              : {}
                          }
                          loading={isLoadingPath}
                        />
                      </MenuContext.Provider>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </ErrorBoundary>

        <Modal
          size="lg"
          show={isRestoreModalVisible}
          onHide={() => setIsRestoreModalVisible(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Restore Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            <p>
              You will restore the following paths, including all files
              contained within them:
            </p>
            <ul id="list_files">
              {selectedRows.map((absolutePath: String) => {
                const file = currentSubArray.find(
                  (file: RepoObject) => file.absolutePath === absolutePath
                );
                return (
                  <li key={file.name}>{currentSelectedPath + file.name}</li>
                );
              })}
            </ul>{" "}
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Comment <span className="red_form">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Comment"
                id="set_comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsRestoreModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={comment === ""}
              onClick={restoreSelectedPaths}
            >
              <RestoreIcon /> Restore Items
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {isTracebilityReportVisible && treeHierarchy && (
        <ErrorBoundary>
          <TracebilityReport
            activityId={activityId}
            onNodeSelect={onNodeSelectFunction}
            onClose={() => {
              setIsTracebilityReportVisible(false);
            }}
            currentFile={{
              versionId: currentSelectedVersionId,
              name: currentSelectedPath
            }}
          />
        </ErrorBoundary>
      )}

      {isActionOfFilesVisible && treeHierarchy && (
        <ActionsOfFiles
          activity={activityData}
          currentFileList={currentFileList}
          onNodeSelect={onNodeSelectFunction}
          onClose={() => {
            setIsActionOfFilesVisible(false);
            setOpenPropertiesModal(false);
          }}
          absolutePath={currentSelectedPath}
          versionId={currentSelectedVersionId}
          isPreselectedRevision={false}
          setIsPreselectedRevision={() => {}}
          refetch={() => {}}
          isHistoricalView={true}
        />
      )}

      {(error ||
        errorPath ||
        errorRestoreMutation ||
        errorAction ||
        errorActionPulled) && (
        <Error
          error={
            error ||
            errorPath ||
            errorRestoreMutation ||
            errorAction ||
            errorActionPulled
          }
        />
      )}
      {dataMutation && (
        <Success
          message={
            dataMutation?.restoreFilePath?.versionIds.length === 0
              ? "No files needed to be modified"
              : `A total of ${dataMutation?.restoreFilePath?.versionIds.length} files were modified.`
          }
        />
      )}
    </>
  );
}
