import { useEffect, useState } from "react";
import { useAddActionMutation } from "../backend/hooks/action/mutationCreateAction";
import { useCreateUploadLinkMutation } from "../backend/hooks/importExportMenu/mutationCreateUploadLinks";
import { useUpdateActionStatusMutation } from "../backend/hooks/action/mutationUpdateActionStatus";
import {
  ActionFileType,
  ActionStatus,
  ActionType,
  RepoObject
} from "../backend/types";
import {
  getChecksums,
  MAX_FILE_SIZE_FOR_SINGLE_UPLOAD
} from "../helpers/fileHelper";
import { useUploadMultipartFile } from "./multipartUploadHook";
import {
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType
} from "../helpers/stringHelper";

const getAbsolutePathOfFile = (
  currentSelectedPath: string,
  fileName: string
) => {
  return currentSelectedPath !== "/" &&
    currentSelectedPath !== "\\" &&
    currentSelectedPath !== " "
    ? currentSelectedPath + fileName
    : fileName;
};

export function useUploadFilesModalHook(
  currentSelectedPath: string,
  currentSubArray: any[],
  activityId: number,
  refetchRepoObjects: Function
) {
  const [isUploadWindowOpen, setIsUploadWindowOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState(0);
  const [filesHashed, setFilesHashed] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [description, setDescription] = useState("");
  const [uncompress, setUncompress] = useState(false);
  const [errorFromUploading, setErrorFromUploading] = useState("");
  const [maxNumberOfUploads, setMaxNumberOfUploads] = useState(0);
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus>(QualityCheckStatus.NOT_STARTED);

  const {
    getUploadLinksForFiles,
    uploadData,
    uploadLoading,
    uploadLinksError
  } = useCreateUploadLinkMutation();

  const { createAction, createActionError, createActionLoading } =
    useAddActionMutation();

  const { updateActionStatus } = useUpdateActionStatusMutation();

  const closeUploadWindow = () => {
    setFilesHashed([]);
    setFiles([]);
    setDescription("");
    setIsUploadWindowOpen(false);
    setFilesToBeUploaded(0);
    setFilesHashed([]);
    setMaxNumberOfUploads(0);
    setQualityCheckStatus(QualityCheckStatus.NOT_STARTED);
  };

  const callbackUpload = () => {
    setIsUploadWindowOpen(false);
    refetchRepoObjects();
    closeUploadWindow();
  };
  const {
    uploadMultipart,
    getMulipartUploadLinkLoading,
    getMulipartUploadLinkError,
    completeMultipartUploadLoading,
    completeMultipartUploadError
  } = useUploadMultipartFile(
    currentSelectedPath,
    files,
    setFilesToBeUploaded,
    setMaxNumberOfUploads,
    getAbsolutePathOfFile,
    activityId,
    qualityCheckStatus,
    uncompress,
    callbackUpload,
    setErrorFromUploading,
    filesToBeUploaded
  );

  const saveFiles = (e: any) => {
    setIsLoading(true);
    getChecksums(e).then((array) => {
      const arrayFinal: any = [];
      array.forEach((checkedFile) => {
        let willOverwrite = false;
        currentSubArray?.forEach((currentFile: RepoObject) => {
          if (checkedFile.file === currentFile.name) {
            willOverwrite = true;
          }
        });
        arrayFinal.push({ ...checkedFile, willOverwrite });
      });

      setFilesHashed((prevFiles) => {
        return [...prevFiles, ...arrayFinal];
      });

      //reset the input element
      const inputElement = document.getElementById(
        "uploadFileInput"
      ) as HTMLInputElement | null;
      if (inputElement) {
        inputElement.value = "";
      }
      setIsLoading(false);
    });

    setFiles((prevFiles) => {
      return [...prevFiles, ...e["target"]["files"]];
    });
  };

  const removeCurrentFileFromFiles = (fileName: string) => {
    setFiles((prevFiles) => {
      return [...prevFiles.filter((file) => file.name !== fileName)];
    });

    setFilesHashed((prevFiles) => {
      return [...prevFiles.filter((file) => file.file !== fileName)];
    });
  };

  const uploadFiles = () => {
    if (
      filesHashed.filter((file) => file.willOverwrite).length > 0 &&
      !window.confirm("Are you sure that you want to overwrite files?")
    ) {
      return;
    }

    const fileList: any[] = [];
    const hashList: any[] = [];
    const actionFileTypes: any[] = [];

    // We first start with the small files
    filesHashed.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE_FOR_SINGLE_UPLOAD) {
        fileList.push(getAbsolutePathOfFile(currentSelectedPath, file.file));
        hashList.push(file.hash);
        actionFileTypes.push(ActionFileType.Output);
      }
    });

    setFilesToBeUploaded((prevState) => prevState + hashList.length);
    setMaxNumberOfUploads((prevState) => prevState + hashList.length);

    createAction({
      variables: {
        actionType: ActionType.Upload,
        activityId,
        description: description,
        actionFiles: [],
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus)
      }
    }).then((actionData: any) => {
      if (fileList.length) {
        getUploadLinksForFiles({
          variables: {
            absolutePaths: fileList,
            md5sums: hashList,
            activityId,
            actionId: Number(actionData.data.createAction.id),
            qualityCheckStatus:
              getQualityCheckStatusKeyFromValue(qualityCheckStatus),
            actionFileTypes,
            uncompressOnUpload: uncompress
          }
        });
      }

      // Then we take care of the big files
      filesHashed.forEach((file) => {
        if (file.size > MAX_FILE_SIZE_FOR_SINGLE_UPLOAD) {
          uploadMultipart(
            file,
            ActionFileType.Output,
            Number(actionData.data.createAction.id)
          );
        }
      });
    });
  };
  useEffect(() => {
    if (filesToBeUploaded <= 0) {
      callbackUpload();
    }
  }, [filesToBeUploaded]);
  //Runs after upload links are pulled
  useEffect(() => {
    if (typeof uploadData !== "undefined") {
      if (uploadData.createUploadLinks.actionStatus === ActionStatus.Pending) {
        //Wrap all uploads in a promise so we can close the window only at the end
        const promiseArray: any = [];
        uploadData.createUploadLinks?.presignedUrls.forEach(
          async (uploadDataParse: any) => {
            const fields = JSON.parse(uploadDataParse.fields);
            const formData = new FormData();
            Object.entries(fields).forEach((element: any) => {
              const [key, value] = element;
              formData.append(key, value);
            });
            //Attach the proper file to the form
            formData.append(
              "file",
              Array.from(files).find((file) => {
                return fields.key.endsWith(file.name);
              })
            );
            promiseArray.push(
              fetch(uploadDataParse.url, {
                method: "POST",
                body: formData
              }).then(() => {
                setFilesToBeUploaded((prevState) => prevState - 1);
              })
            );
          }
        );

        Promise.all(promiseArray)
          .finally(() => {
            updateActionStatus({
              variables: {
                actionId: uploadData.createUploadLinks.actionId,
                actionStatus: ActionStatus.Success
              }
            });
          })
          .catch((err) => {
            updateActionStatus({
              variables: {
                actionId: uploadData.createUploadLinks.actionId,
                actionStatus: ActionStatus.Error,
                actionStatusDescription: err.toString()
              }
            });
            setErrorFromUploading("Error From Uploading");
            console.error(err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData]);

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  return {
    isUploadWindowOpen,
    setIsUploadWindowOpen,
    isLoading,
    setIsLoading,
    filesToBeUploaded,
    setFilesToBeUploaded,
    maxNumberOfUploads,
    filesHashed,
    setFilesHashed,
    files,
    setFiles,
    description,
    setDescription,
    qualityCheckStatus,
    setQualityCheckStatus,
    uncompress,
    setUncompress,
    errorFromUploading,
    setErrorFromUploading,
    closeUploadWindow,
    saveFiles,
    uploadFiles,
    uploadLoading,
    createActionError,
    createActionLoading,
    removeCurrentFileFromFiles,
    uploadLinksError,
    getMulipartUploadLinkLoading,
    getMulipartUploadLinkError,
    completeMultipartUploadLoading,
    completeMultipartUploadError,
    changeQualityCheckedStatus
  };
}
