import { useLazyQuery, useMutation } from "@apollo/client";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DescriptionIcon from "@mui/icons-material/Description";
import LeakRemoveIcon from "@mui/icons-material/LeakRemove";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import MaterialButton from "@mui/material/Button";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useContext, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { UPDATE_ACTION_STATUS_MUTATION } from "../../backend/hooks/action/mutationUpdateActionStatus";
import { GET_DOWNLOAD_LINKS } from "../../backend/hooks/importExportMenu/queryGetDownloadLinks";
import { useGetFilePartialContentQuery } from "../../backend/hooks/pmxActivity/queryGetFilePartialContent";
import { ActionStatus } from "../../backend/types";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import Error from "../abstractComponents/error";
import { PdfPreview } from "./pdfPreview";
import { useHistory } from "react-router-dom";

interface FilePreviewProps {
  absolutePath: string;
  versionId: string;
  revision: string;
  setTableMenuWasOpenOnPath: Function;
  setIsActionOfFilesVisible: Function;
  openPropertiesModal: boolean;
  setIsPreselectedRevision: Function;
  setIsTracebilityReportVisible: Function;
  repoId: string;
  setCompareFile: Function;
}

// Once you select a file, the preview will show you the content of the file, if available. Supports text, and images
export function FilePreview(props: FilePreviewProps) {
  const { activityData } = useContext(PmxActivityDetailsContext);
  const history = useHistory();

  const isPropImage = () => {
    return (
      props.absolutePath.endsWith(".png") ||
      props.absolutePath.endsWith(".jpeg") ||
      props.absolutePath.endsWith(".jpg") ||
      props.absolutePath.endsWith(".gif")
    );
  };

  const isPdfFile = () => {
    return props.absolutePath.endsWith(".pdf");
  };

  const unicodePreviewCheck = () => {
    if (!isPropImage()) {
      return fileDetails?.getFilePartialContent.isPreviewAvailable;
    }
  };

  const { fileDetails, isLoading, error, refetchFilePartialContent } =
    useGetFilePartialContentQuery(
      props.repoId,
      props.absolutePath,
      props.versionId
    );

  const handleDownloadFile = () => {
    getDownloadLinkForFiles({
      variables: {
        absolutePaths: [props.absolutePath],
        activityId: Number(activityData.id),
        versionIds: props.versionId ? [props.versionId] : [],
        repoId: props.repoId
      }
    });
  };

  useEffect(() => {
    refetchFilePartialContent();
  }, [props.repoId]);

  const [
    getDownloadLinkForFiles,
    { error: errorDownload, data: downloadUrls }
  ] = useLazyQuery(GET_DOWNLOAD_LINKS);

  const [updateActionStatus] = useMutation(UPDATE_ACTION_STATUS_MUTATION, {
    onError(err: any) {
      console.log(err);
    }
  });

  useEffect(() => {
    refetchFilePartialContent();
  }, [props.repoId]);

  useEffect(() => {
    if (typeof downloadUrls !== "undefined") {
      if (downloadUrls.getDownloadLinks.actionStatus === ActionStatus.Pending) {
        try {
          downloadUrls?.getDownloadLinks?.presignedUrls.forEach((url: any) => {
            window.open(url.url);
          });
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Success
            }
          });
        } catch (err: any) {
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Error,
              actionStatusDescription: err.message
            }
          });
        }
      }
    }
  }, [downloadUrls]);

  useEffect(() => {
    if (props.openPropertiesModal) {
      setTimeout(() => {
        props.setTableMenuWasOpenOnPath(props.absolutePath);
        props.setIsActionOfFilesVisible(true);
        props.setIsPreselectedRevision(true);
      }, 1500);
    }
  }, []);

  return (
    <>
      <div className="table_view_upper_menu">
        <b>{props.absolutePath}</b>{" "}
        {props.revision && props.revision !== "undefined" && (
          <>({props.revision})</>
        )}
        <MaterialButton
          size="small"
          variant="outlined"
          startIcon={<DescriptionIcon />}
          onClick={() => {
            props.setTableMenuWasOpenOnPath(props.absolutePath);
            props.setIsActionOfFilesVisible(true);
          }}
          className="float-end properties_file_button"
        >
          Properties
        </MaterialButton>
        <MaterialButton
          size="small"
          variant="outlined"
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownloadFile}
          className="float-end download_file_button"
        >
          Download
        </MaterialButton>
        <MaterialButton
          size="small"
          variant="outlined"
          startIcon={<LeakRemoveIcon />}
          onClick={() => {
            props.setTableMenuWasOpenOnPath(props.absolutePath);
            props.setIsTracebilityReportVisible(true);
          }}
          className="float-end download_file_button"
        >
          Tracebility
        </MaterialButton>
        {unicodePreviewCheck() && (
          <MaterialButton
            size="small"
            variant="outlined"
            startIcon={<CompareArrowsIcon />}
            onClick={() => {
              history.push(
                `/activity/${activityData.id}/compare?sourceVersionId=${props.versionId}&sourceRepoId=${props.repoId}`
              );
            }}
            className="float-end download_file_button"
          >
            Compare
          </MaterialButton>
        )}
      </div>
      <br />
      {isLoading ? (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      ) : error || errorDownload ? (
        <Error error={error || errorDownload} />
      ) : (
        <>
          {fileDetails?.getFilePartialContent.isPreviewAvailable ? (
            isPropImage() ? (
              <img
                className="preview_display_image"
                alt={"Image for " + props.absolutePath}
                src={fileDetails?.getFilePartialContent.content}
              />
            ) : (
              <>
                <p>Previewing first 100 lines</p>
                <Form.Control
                  id="token-textarea"
                  className=""
                  as="textarea"
                  disabled
                  rows={15}
                  value={
                    fileDetails
                      ? fileDetails?.getFilePartialContent.isTruncated
                        ? fileDetails?.getFilePartialContent.content +
                          "\n[...file truncated...]"
                        : fileDetails?.getFilePartialContent.content
                      : ""
                  }
                ></Form.Control>
              </>
            )
          ) : isPdfFile() ? (
            <PdfPreview
              absolutePath={props.absolutePath}
              versionId={props.versionId}
              repoId={props.repoId}
            />
          ) : (
            <p>No preview available</p>
          )}
        </>
      )}
    </>
  );
}
