import FolderIcon from "@mui/icons-material/Folder";
import FileIcon from "../abstractComponents/fileIcon";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import MaterialButton from "@mui/material/Button";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  NavDropdown
} from "react-bootstrap";
import { useImportContentFromSvnMutation } from "../../backend/hooks/importExportMenu/mutationImportSvnContent";
import { useGetSvnContentQuery } from "../../backend/hooks/importExportMenu/queryGetSvnContent";
import {
  encryptStringWithRsaPublicKey,
  formatFileSize,
  MAXIMUM_CLEAR_PASSWORD,
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType
} from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import Success from "../abstractComponents/success";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customFilters } from "../../helpers/tableViewHelper";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useGetSvnMetadataQuery } from "../../backend/hooks/importExportMenu/queryGetSvnMetadata";
import { useGetSpmReRevisionsQuery } from "../../backend/hooks/importExportMenu/queryGetSvnRevisions";
import { ActionStatus } from "../../backend/types";

// Component that renders everything related to Svn Import
export default function ImportFromSvn(props: {
  currentFolder: string;
  disabled: boolean;
  currentSubArray: [any];
}) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const columnsSvn = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => {
        let weHaveSameName = false;
        props.currentSubArray?.forEach((element: any) => {
          if (element.name === params.row.name) {
            weHaveSameName = true;
          }
        });
        return (
          <div key={params.row.name} style={{ width: "100%" }}>
            {params.row.isDir ? (
              <MaterialButton
                variant="text"
                onClick={() => onClickOnFileOrFolder(params.row.id)}
                className="button_without_text_transform float-start"
              >
                <>
                  <FolderIcon
                    className={
                      weHaveSameName
                        ? "grey_color folder_icon warning_clean"
                        : "grey_color folder_icon"
                    }
                    style={{ fontSize: 50 }}
                  />
                  <span className="folder_name_table_view_span">
                    {params.row.name + (weHaveSameName ? " (name exists)" : "")}{" "}
                  </span>
                </>
              </MaterialButton>
            ) : (
              <>
                <FileIcon
                  fileName={params.row.name}
                  isValid={false}
                  fontSize={"large"}
                  className={weHaveSameName ? "warning_clean" : ""}
                />
                {params.row.name + (weHaveSameName ? " (name exists)" : "")}
              </>
            )}
          </div>
        );
      },
      valueGetter: (params: any) => params.row.name
    },
    {
      field: "size",
      headerName: "Size",
      flex: 2,
      filterOperators: customFilters,
      valueGetter: (params: any) => params.row.size,
      renderCell: (params: any) =>
        params.row.isDir ? "" : formatFileSize(params.row.size),
      sortComparator: (v1: any, v2: any, param1: any, param2: any) =>
        (param1.api.getCellValue(param1.id, "size") as number) -
        (param2.api.getCellValue(param2.id, "size") as number)
    },
    {
      field: "isDir",
      headerName: "isDir",
      hide: true
    }
  ];

  const columnsMetadata = [
    {
      field: "spmReFolder",
      headerName: "name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <div key={params.row.name} style={{ width: "100%" }}>
          <MaterialButton
            variant="text"
            onClick={() => {
              onClickOnFileOrFolder(params.row.name);
            }}
            className="button_without_text_transform float-start"
          >
            <>
              <FolderIcon
                className="grey_color folder_icon"
                style={{ fontSize: 50 }}
              />
              <span className="folder_name_table_view_span">
                {params.row.name}
              </span>
            </>
          </MaterialButton>
        </div>
      ),
      valueGetter: (params: any) => params.row.spmReFolder
    }
  ];
  const [svnContent, setSvnContent] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedRevision, setSelectedRevision] = useState<string | null>(null);
  const [rootAddress, setRootAddress] = useState("");
  const [selectedRepository, setSelectedRepository] = useState("");
  const [weHaveSameNameSomewhere, setWeHaveSameNameSomewhere] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus>(QualityCheckStatus.NOT_STARTED);
  const [description, setDescription] = useState("");
  const [svnUsername, setSvnUsername] = useState(
    localStorage.getItem("svnUsername") || ""
  );
  const [svnPassword, setSvnPassword] = useState(
    localStorage.getItem("svnPassword") || ""
  );
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectableRevisions, setSelectableRevisions] = useState<string[]>([]);

  useEffect(() => {
    setWeHaveSameNameSomewhere(false);
    props.currentSubArray?.forEach((element: any) => {
      selectedRows.forEach((selectedElement: string) => {
        if (element.name === selectedElement.split("/").slice(-1)[0]) {
          setWeHaveSameNameSomewhere(true);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const handleLoadSvnFolder = (password?: string) => {
    getSpmRePaths({
      variables: {
        username: svnUsername,
        encryptedPassword: password ? password : svnPassword,
        ...(selectedRevision &&
          selectedRevision !== "HEAD" && {
            spmReRevision: selectedRevision
          }),
        spmReFolder: selectedRepository
      }
    });
  };

  const { getSpmRePaths, error, loading, data } = useGetSvnContentQuery();

  useEffect(() => {
    if (selectedRepository && data?.getSpmRePaths) {
      let svnData = data?.getSpmRePaths.spmRePaths
        .filter((svnFile: any) => {
          let name = svnFile.name.endsWith("/")
            ? svnFile.name.split("/").reverse()[1] + "/"
            : svnFile.name.split("/").reverse()[0];
          let path = svnFile.name.replace(name, "");
          return path === rootAddress;
        })
        .map((svnFile: any) => ({
          id: svnFile.name,
          name: svnFile.name.endsWith("/")
            ? svnFile.name.split("/").reverse()[1]
            : svnFile.name.split("/").reverse()[0],
          isDir: svnFile.name.endsWith("/"),
          size: svnFile.size
        }))
        .sort((a: any, b: any) => Number(b.isDir) - Number(a.isDir));

      setSvnContent(svnData);
    } else {
      setSvnContent([]);
    }
  }, [data, rootAddress]);

  const { getSpmReFolders, errorMetadata, loadingMetadata, metadata } =
    useGetSvnMetadataQuery();

  const onClickOnFileOrFolder = (address: string) => {
    if (selectedRepository === "") {
      setSelectedRepository(address);
    } else {
      setRootAddress(address);
    }
  };

  useEffect(() => {
    if (selectedRepository) {
      handleLoadSvnFolder();
    }
  }, [selectedRevision, selectedRepository]);

  useEffect(() => {
    if (search.length > 2 && svnUsername.length > 0 && svnPassword.length > 0) {
      // wait 1 sec before the call
      const timeOutId = setTimeout(() => {
        let newPassword = svnPassword;

        if (
          svnPassword.length < MAXIMUM_CLEAR_PASSWORD &&
          !svnPassword.endsWith("=")
        ) {
          //Password is not encrypted, so we encrypt it
          newPassword = encryptStringWithRsaPublicKey(svnPassword);
          setSvnPassword(newPassword);
          localStorage.setItem("svnUsername", svnUsername);
          localStorage.setItem("svnPassword", newPassword);
        }

        getSpmReFolders({
          variables: {
            username: svnUsername,
            encryptedPassword: newPassword,
            search: search
          }
        });
        setSelectedRevision(null);
        setRootAddress("");
      }, 1000);

      return () => clearTimeout(timeOutId);
    }
  }, [search, svnUsername, svnPassword]);

  const clickOnBack = () => {
    if (rootAddress == "") {
      setSelectedRepository("");
    } else {
      let root = rootAddress.split("/").reverse()[1] + "/";
      console.log(root, rootAddress.replace(root, ""));
      setRootAddress(rootAddress.replace(root, ""));
    }
  };

  const handleSelectMultipleRows = (e: any) => {
    setSelectedRows(e);
  };

  const { getSpmReRevisions, errorRevisions, loadingRevisions, revisions } =
    useGetSpmReRevisionsQuery();

  const loadRevisions = () => {
    if (!revisions?.getSpmReRevisions) {
      getSpmReRevisions({
        variables: {
          username: svnUsername,
          encryptedPassword: svnPassword,
          spmReFolder: selectedRepository
        }
      });
    }
  };

  useEffect(() => {
    setSelectableRevisions(
      revisions?.getSpmReRevisions?.spmReRevisions.reverse()
    );
  }, [revisions]);

  const {
    importContentMutation,
    svnImportMutationData,
    isLoadingSvnMutation,
    errorSvnMutation
  } = useImportContentFromSvnMutation();

  const closeModal = () => {
    setIsImportModalVisible(false);
    setQualityCheckStatus(QualityCheckStatus.NOT_STARTED);
    setSelectedRows([]);
    setDescription("");
    setSearch("");
    setSelectedRevision(null);
    setRootAddress("");
    setSelectedRepository("");
  };

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  const importAll = () => {
    if (
      weHaveSameNameSomewhere &&
      !window.confirm("Are you sure you want to overwrite existing files?")
    ) {
      return;
    }

    importContentMutation({
      variables: {
        username: svnUsername,
        encryptedPassword: svnPassword,
        spmReFolder: selectedRepository,
        spmRePaths: selectedRows,
        repoFolder: props.currentFolder,
        activityId: activityId,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus),
        description: description,
        ...(selectedRevision &&
          selectedRevision !== "HEAD" && {
            spmReRevision: selectedRevision
          })
      }
    }).then(() => {
      if (!error) {
        closeModal();
      }
    });
  };

  return (
    <>
      <NavDropdown.Item
        size="small"
        variant="outlined"
        onClick={() => {
          setIsImportModalVisible(true);
        }}
        disabled={props.disabled}
        id="importFromSvnItem"
      >
        <QueuePlayNextIcon /> SPM RE Import
      </NavDropdown.Item>

      {svnImportMutationData?.importSpmReContent?.actionStatus ===
        ActionStatus.Pending && (
        <Success message="SVN import started. It can take up to 15 Minutes." />
      )}
      {(error ||
        errorSvnMutation ||
        errorMetadata ||
        errorRevisions ||
        svnImportMutationData?.importSpmReContent?.actionStatus ===
          ActionStatus.Error) && (
        <Error
          error={error || errorSvnMutation || errorMetadata || errorRevisions}
        />
      )}
      <Modal
        show={isImportModalVisible}
        onHide={closeModal}
        enforceFocus={false}
        dialogClassName="modal-80w"
        aria-labelledby="importModscapeTitle"
      >
        <Modal.Header closeButton>
          <Modal.Title id="importFromModscapeTitle">
            Import from SPM RE into &quot;{activityData.trialNumber}/
            {props.currentFolder}&quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row
              className={
                svnPassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>SPM RE Username:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter username"
                  id="importFromSvnUserFormControl"
                  value={svnUsername}
                  onChange={(e) => setSvnUsername(e.target.value)}
                />
              </Col>
            </Row>
            <Row
              className={
                svnPassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>SPM RE Password:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="password"
                  required
                  placeholder="Enter password"
                  id="importFromSvnPasswordFormControl"
                  value={svnPassword}
                  onChange={(e) => setSvnPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Form.Label>Search:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter at least 3 letters"
                  id="searchSvnParam"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setSelectedRepository("");
                  }}
                />
              </Col>
            </Row>

            {(loading || isLoadingSvnMutation || loadingMetadata) && (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            )}
            {data && selectedRepository !== "" && search.length > 2 && (
              <>
                <Row>
                  <Col xs={2}>
                    <Form.Label>Revision Number:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      aria-label="HEAD"
                      value={selectedRevision}
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        event.preventDefault();
                        setSelectedRevision(event.target.value);
                      }}
                      onClick={loadRevisions}
                      key="edit_revision"
                      id="edit_revision"
                    >
                      <option>HEAD</option>
                      {selectableRevisions &&
                        selectableRevisions.map((revisionNumber: string) => (
                          <option key={revisionNumber} value={revisionNumber}>
                            {revisionNumber}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  {loadingRevisions && (
                    <Col xs={2}>
                      <Spinner className="spinner_color" />
                    </Col>
                  )}
                </Row>
                <br />
                <br />
                <Row>
                  <Col xs={2}>
                    <Form.Label>Import Description:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Enter description"
                      id="importFromSvnDescriptionFormControl"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Label>Quality Controlled Status</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      value={String(
                        getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                      )}
                      onChange={changeQualityCheckedStatus}
                      key="svn_quality_controlled"
                      id="importFromSvnQualityControlledFormSelect"
                    >
                      {Object.entries(QualityCheckStatus).map((element) => (
                        <option key={element[0]} value={element[0]}>
                          {element[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col xs={2}>
                    <Button variant="light" onClick={clickOnBack}>
                      <ArrowBackIcon className="w-auto align-self-center" />{" "}
                      Back
                    </Button>
                  </Col>
                  <Col xs={3}>
                    <Button
                      variant="primary"
                      disabled={selectedRows.length === 0}
                      onClick={importAll}
                      id="finishImportFromSvnButton"
                    >
                      Import Selected File
                      {selectedRows.length > 1 &&
                        "s (" + selectedRows.length + ")"}
                    </Button>
                  </Col>
                  <Col>
                    Location:{" "}
                    <i>
                      {selectedRepository}/{rootAddress}
                    </i>
                  </Col>
                </Row>
                <br />
                <Row>
                  <DataGridPro
                    rows={svnContent}
                    columns={columnsSvn}
                    pageSize={50}
                    rowHeight={60}
                    autoHeight
                    disableSelectionOnClick
                    checkboxSelection
                    className="browser_repository_table"
                    selectionModel={selectedRows}
                    onSelectionModelChange={handleSelectMultipleRows}
                  />
                </Row>
              </>
            )}
            {metadata && selectedRepository === "" && search.length > 2 && (
              <>
                <DataGridPro
                  rows={
                    metadata?.getSpmReFolders
                      ? metadata?.getSpmReFolders?.spmReFolders.map(
                          (name: string) => ({ name })
                        )
                      : []
                  }
                  columns={columnsMetadata}
                  pageSize={50}
                  rowHeight={60}
                  autoHeight
                  disableSelectionOnClick
                  className="browser_repository_table_metadata"
                  getRowId={(row) => row.name}
                />
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
