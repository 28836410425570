import FolderIcon from "@mui/icons-material/Folder";
import FileIcon from "../abstractComponents/fileIcon";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import MaterialButton from "@mui/material/Button";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  NavDropdown
} from "react-bootstrap";
import { useImportContentFromModspaceMutation } from "../../backend/hooks/importExportMenu/mutationImportModspaceContent";
import { useGetModspaceContentQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceContent";
import {
  encryptStringWithRsaPublicKey,
  formatFileSize,
  MAXIMUM_CLEAR_PASSWORD,
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType
} from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import Success from "../abstractComponents/success";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customFilters } from "../../helpers/tableViewHelper";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useGetModspaceMetadataQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceMetadata";
import { useGetModspaceRevisionsQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceRevisions";
import { ActionStatus } from "../../backend/types";

export default function ImportFromModspace(props: {
  currentFolder: string;
  disabled: boolean;
  currentSubArray: [any];
}) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);

  const columnsModspace = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => {
        let weHaveSameName = false;
        props.currentSubArray?.forEach((element: any) => {
          if (element.name === params.row.name) {
            weHaveSameName = true;
          }
        });
        return (
          <div key={params.row.name} style={{ width: "100%" }}>
            {params.row.isDir ? (
              <MaterialButton
                variant="text"
                onClick={() => onClickOnFileOrFolder(params.row.id)}
                className="button_without_text_transform float-start"
              >
                <>
                  <FolderIcon
                    className={
                      weHaveSameName
                        ? "grey_color folder_icon warning_clean"
                        : "grey_color folder_icon"
                    }
                    style={{ fontSize: 50 }}
                  />
                  <span className="folder_name_table_view_span">
                    {params.row.name + (weHaveSameName ? " (name exists)" : "")}{" "}
                  </span>
                </>
              </MaterialButton>
            ) : (
              <>
                <FileIcon
                  fileName={params.row.name}
                  isValid={false}
                  fontSize={"large"}
                  className={weHaveSameName ? "warning_clean" : ""}
                />
                {params.row.name + (weHaveSameName ? " (name exists)" : "")}
              </>
            )}
          </div>
        );
      },
      valueGetter: (params: any) => params.row.name
    },
    {
      field: "size",
      headerName: "Size",
      flex: 2,
      filterOperators: customFilters,
      valueGetter: (params: any) => params.row.size,
      renderCell: (params: any) =>
        params.row.isDir ? "" : formatFileSize(params.row.size),
      sortComparator: (v1: any, v2: any, param1: any, param2: any) =>
        (param1.api.getCellValue(param1.id, "size") as number) -
        (param2.api.getCellValue(param2.id, "size") as number)
    },
    {
      field: "isDir",
      headerName: "isDir",
      hide: true
    }
  ];

  const columnsMetadata = [
    {
      field: "modspaceFolder",
      headerName: "name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <div key={params.row.name} style={{ width: "100%" }}>
          <MaterialButton
            variant="text"
            onClick={() => {
              onClickOnFileOrFolder(params.row.name);
            }}
            className="button_without_text_transform float-start"
          >
            <>
              <FolderIcon
                className="grey_color folder_icon"
                style={{ fontSize: 50 }}
              />
              <span className="folder_name_table_view_span">
                {params.row.name}
              </span>
            </>
          </MaterialButton>
        </div>
      ),
      valueGetter: (params: any) => params.row.name
    }
  ];

  const [modspaceContent, setModspaceContent] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedRevision, setSelectedRevision] = useState<string | null>(null);
  const defaultRootAddressModspaceImport =
    localStorage.getItem("defaultRootAddressModspaceImport") &&
    localStorage.getItem("defaultRootAddressModspaceImport") !== "undefined" &&
    localStorage.getItem("defaultRootAddressModspaceImport") !== ""
      ? String(localStorage.getItem("defaultRootAddressModspaceImport"))
      : "/entry ID/entry name/";
  const [rootAddress, setRootAddress] = useState(
    defaultRootAddressModspaceImport
  );
  const [selectableRevisions, setSelectableRevisions] = useState<string[]>([]);
  const [modspaceUsername, setModspaceUsername] = useState(
    localStorage.getItem("modspaceUsername") || ""
  );
  const [modspacePassword, setModspacePassword] = useState(
    localStorage.getItem("modspacePassword") || ""
  );
  const [selectedRepository, setSelectedRepository] = useState("");
  const [weHaveSameNameSomewhere, setWeHaveSameNameSomewhere] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus>(QualityCheckStatus.NOT_STARTED);
  const [description, setDescription] = useState("");
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  useEffect(() => {
    setWeHaveSameNameSomewhere(false);
    props.currentSubArray?.forEach((element: any) => {
      selectedRows.forEach((selectedElement: string) => {
        if (element.name === selectedElement.split("/").slice(-1)[0]) {
          setWeHaveSameNameSomewhere(true);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const handleLoadModspaceFolder = (password?: string) => {
    getModspacePaths({
      variables: {
        username: modspaceUsername,
        encryptedPassword: password ? password : modspacePassword,
        ...(selectedRevision &&
          selectedRevision !== "HEAD" && {
            modspaceRevision: selectedRevision
          }),
        modspaceFolder: selectedRepository
      }
    });
  };

  const { getModspacePaths, error, loading, data } =
    useGetModspaceContentQuery();

  useEffect(() => {
    if (selectedRepository && data?.getModspacePaths.modspacePaths) {
      let modspaceData = data?.getModspacePaths.modspacePaths
        .filter((modspaceFile: any) => {
          let name = modspaceFile.name.endsWith("/")
            ? modspaceFile.name.split("/").reverse()[1] + "/"
            : modspaceFile.name.split("/").reverse()[0];
          let path = modspaceFile.name.replace(name, "");
          return path === rootAddress;
        })
        .map((modspaceFile: any) => ({
          id: modspaceFile.name,
          name: modspaceFile.name.endsWith("/")
            ? modspaceFile.name.split("/").reverse()[1]
            : modspaceFile.name.split("/").reverse()[0],
          isDir: modspaceFile.name.endsWith("/"),
          size: modspaceFile.size
        }))
        .sort((a: any, b: any) => Number(b.isDir) - Number(a.isDir));

      setModspaceContent(modspaceData);
    } else {
      setModspaceContent([]);
    }
  }, [data, rootAddress]);

  const {
    getModspaceFolders,
    errorModspaceMetadata,
    loadingModspaceMetadata,
    modspaceMetadata
  } = useGetModspaceMetadataQuery();

  const onClickOnFileOrFolder = (address: string) => {
    if (selectedRepository === "") {
      setSelectedRepository(address);
    } else {
      setRootAddress(address);
    }
  };

  useEffect(() => {
    if (selectedRepository) {
      handleLoadModspaceFolder();
    }
  }, [selectedRevision, selectedRepository]);

  useEffect(() => {
    if (
      search.length > 2 &&
      modspaceUsername.length > 0 &&
      modspaceUsername.length > 0
    ) {
      // wait 1 sec before the call
      const timeOutId = setTimeout(() => {
        let newPassword = modspacePassword;

        if (
          modspacePassword.length < MAXIMUM_CLEAR_PASSWORD &&
          !modspacePassword.endsWith("=")
        ) {
          //Password is not encrypted, so we encrypt it
          newPassword = encryptStringWithRsaPublicKey(modspacePassword);
          setModspacePassword(newPassword);
          localStorage.setItem("modspaceUsername", modspaceUsername);
          localStorage.setItem("modspacePassword", newPassword);
        }

        getModspaceFolders({
          variables: {
            username: modspaceUsername,
            encryptedPassword: newPassword,
            search: search
          }
        });
        setSelectedRevision(null);
        setRootAddress("");
      }, 1000);

      return () => clearTimeout(timeOutId);
    }
  }, [search, modspaceUsername, modspacePassword]);

  const {
    getModspaceRevisions,
    errorModspaceRevisions,
    loadingModspaceRevisions,
    modspaceRevisions
  } = useGetModspaceRevisionsQuery();

  const loadRevisions = () => {
    if (!modspaceRevisions?.getSpmReRevisions) {
      getModspaceRevisions({
        variables: {
          username: modspaceUsername,
          encryptedPassword: modspacePassword,
          modspaceFolder: selectedRepository
        }
      });
    }
  };

  useEffect(() => {
    setSelectableRevisions(
      modspaceRevisions?.getModspaceRevisions?.modspaceRevisions.reverse()
    );
  }, [modspaceRevisions]);

  const clickOnBack = () => {
    if (rootAddress == "") {
      setSelectedRepository("");
    } else {
      let root = rootAddress.split("/").reverse()[1] + "/";
      console.log(root, rootAddress.replace(root, ""));
      setRootAddress(rootAddress.replace(root, ""));
    }
  };

  const handleSelectMultipleRows = (e: any) => {
    setSelectedRows(e);
  };

  const {
    importContentMutation,
    importMutationData,
    isLoadingMutation,
    errorMutation
  } = useImportContentFromModspaceMutation();

  const closeModal = () => {
    setIsImportModalVisible(false);
    setQualityCheckStatus(QualityCheckStatus.NOT_STARTED);
    setSelectedRows([]);
    setDescription("");
    setSearch("");
    setSelectedRevision(null);
    setRootAddress("");
    setSelectedRepository("");
  };

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  const importAll = () => {
    if (
      weHaveSameNameSomewhere &&
      !window.confirm("Are you sure you want to overwrite existing files?")
    ) {
      return;
    }

    importContentMutation({
      variables: {
        username: modspaceUsername,
        encryptedPassword: modspacePassword,
        modspaceFolder: selectedRepository,
        modspacePaths: selectedRows,
        repoFolder: props.currentFolder,
        activityId: activityId,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus),
        description: description,
        ...(selectedRevision &&
          selectedRevision !== "HEAD" && {
            modspaceRevision: selectedRevision
          })
      }
    }).then(() => {
      if (!error) {
        closeModal();
      }
    });
  };

  return (
    <>
      <NavDropdown.Item
        size="small"
        variant="outlined"
        onClick={() => {
          setIsImportModalVisible(true);
        }}
        disabled={props.disabled}
        id="importFromModspaceItem"
      >
        <AddToHomeScreenIcon /> Modspace Import
      </NavDropdown.Item>

      {importMutationData?.importModspaceContent?.actionStatus ===
        ActionStatus.Pending && (
        <Success message="Modspace import started. It can take up to 15 Minutes." />
      )}
      {(error ||
        errorMutation ||
        errorModspaceMetadata ||
        errorModspaceRevisions ||
        importMutationData?.importModspaceContent?.actionStatus ===
          ActionStatus.Error) && (
        <Error
          error={
            error ||
            errorMutation ||
            errorModspaceMetadata ||
            errorModspaceRevisions
          }
        />
      )}
      <Modal
        show={isImportModalVisible}
        onHide={closeModal}
        enforceFocus={false}
        dialogClassName="modal-80w"
        aria-labelledby="importModscapeTitle"
      >
        <Modal.Header closeButton>
          <Modal.Title id="importFromModscapeTitle">
            Import from Modspace into &quot;{activityData.trialNumber}/
            {props.currentFolder}&quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row
              className={
                modspacePassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>Modspace Username:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter username"
                  id="importFromModspaceUserFormControl"
                  value={modspaceUsername}
                  onChange={(e) => setModspaceUsername(e.target.value)}
                />
              </Col>
            </Row>
            <Row
              className={
                modspacePassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>Modspace Password:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="password"
                  required
                  placeholder="Enter password"
                  id="importFromModspacePasswordFormControl"
                  value={modspacePassword}
                  onChange={(e) => setModspacePassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Form.Label>Search:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter at least 3 letters"
                  id="searchModspaceParam"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setSelectedRepository("");
                  }}
                />
              </Col>
            </Row>

            {(loading || isLoadingMutation || loadingModspaceMetadata) && (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            )}
            {data && selectedRepository !== "" && search.length > 2 && (
              <>
                <Row>
                  <Col xs={2}>
                    <Form.Label>Revision Number:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      aria-label="HEAD"
                      value={selectedRevision}
                      onClick={loadRevisions}
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        event.preventDefault();
                        setSelectedRevision(event.target.value);
                      }}
                      key="edit_revision"
                      id="edit_revision"
                    >
                      <option>HEAD</option>

                      {selectableRevisions &&
                        selectableRevisions.map((revisionNumber: string) => (
                          <option key={revisionNumber} value={revisionNumber}>
                            {revisionNumber}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  {loadingModspaceRevisions && (
                    <Col xs={2}>
                      <Spinner className="spinner_color" />
                    </Col>
                  )}
                </Row>

                <br />
                <br />
                <Row>
                  <Col xs={2}>
                    <Form.Label>Import Description:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Enter description"
                      id="importFromModspaceDescriptionFormControl"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Label>Quality Controlled Status</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      value={String(
                        getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                      )}
                      onChange={changeQualityCheckedStatus}
                      key="modspace_quality_controlled"
                      id="importFromModspaceQualityControlledFormSelect"
                    >
                      {Object.entries(QualityCheckStatus).map((element) => (
                        <option key={element[0]} value={element[0]}>
                          {element[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col xs={2}>
                    <Button variant="light" onClick={clickOnBack}>
                      <ArrowBackIcon className="w-auto align-self-center" />{" "}
                      Back
                    </Button>
                  </Col>
                  <Col xs={3}>
                    <Button
                      variant="primary"
                      disabled={selectedRows.length === 0}
                      onClick={importAll}
                      id="finishImportFromModspaceButton"
                    >
                      Import Selected File
                      {selectedRows.length > 1 &&
                        "s (" + selectedRows.length + ")"}
                    </Button>
                  </Col>
                  <Col>
                    Location:{" "}
                    <i>
                      {selectedRepository}/{rootAddress}
                    </i>
                  </Col>
                </Row>
                <br />
                <Row>
                  <DataGridPro
                    rows={modspaceContent}
                    columns={columnsModspace}
                    pageSize={50}
                    rowHeight={60}
                    autoHeight
                    disableSelectionOnClick
                    checkboxSelection
                    className="browser_repository_table"
                    selectionModel={selectedRows}
                    onSelectionModelChange={handleSelectMultipleRows}
                  />
                </Row>
              </>
            )}
            {modspaceMetadata &&
              selectedRepository === "" &&
              search.length > 2 && (
                <>
                  <DataGridPro
                    rows={
                      modspaceMetadata?.getModspaceFolders
                        ? modspaceMetadata?.getModspaceFolders.modspaceFolders.map(
                            (name: string) => ({ name })
                          )
                        : []
                    }
                    columns={columnsMetadata}
                    pageSize={50}
                    rowHeight={60}
                    autoHeight
                    disableSelectionOnClick
                    className="browser_repository_table_metadata"
                    getRowId={(row) => row.name}
                  />
                </>
              )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
